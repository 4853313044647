import * as React from "react";
import { memo } from "react";

import { LoadingPage } from "@hl/base-components/lib/LoadingPage";
import { NotFound } from "@hl/shared-features/lib/features/layout";
import loadable from "@loadable/component";
import { useParams } from "react-router-dom";

import { TOOLS_URL } from "~config";

import { useGetCollectionByOnChainIdQuery } from "./queries.graphql.generated";

const MintPage = loadable(() => import("./index"));
const MintPageByOnChainId = () => {
  const { onChainId } = useParams();

  const { data, loading } = useGetCollectionByOnChainIdQuery({
    variables: {
      onChainId: onChainId ?? "",
    },
  });
  if (loading) {
    return <LoadingPage />;
  }
  if (!data) {
    return <NotFound redirectUrl={TOOLS_URL.base} />;
  }
  const collectionId = data.getCollectionByOnChainId.id;

  return <MintPage collectionId={collectionId} />;
};

export default memo(MintPageByOnChainId);
