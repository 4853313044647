import { useEffect, useRef, useState } from "react";

import { useTheme } from "@emotion/react";
import { LoadingPage } from "@hl/base-components/lib/LoadingPage";
import { CoinsStacked02 } from "@hl/base-components/lib/assets/icons/HDS";
import { LargeChevronDown } from "@hl/base-components/lib/assets/icons.generated";
import { OUTLINE_COLOR } from "@hl/base-components/lib/theme/button";
import EmailUpdates from "@hl/shared-features/lib/features/email-updates/EmailUpdates";
import { AppSEO, NotFound } from "@hl/shared-features/lib/features/layout";
import useGenArtAspectRatio from "@hl/shared-features/lib/hooks/useAspectRatio";
import useIsScrollTop from "@hl/shared-features/lib/hooks/useIsScrollTop";
import {
  AppShell,
  Box,
  Card,
  Center,
  Container,
  Flex,
  Header,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import {
  useElementSize,
  useMediaQuery,
  useViewportSize,
  useWindowScroll,
} from "@mantine/hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ReferralButton from "~features/share/ReferralButton";
import { useMintPage } from "~hooks/useMintPage";
import { MintContext } from "~hooks/useMintState";

import { getMintPageUrl } from "../../../config";
import { MintingToken } from "../../claim-mints/vars";
import { AppHeader } from "../../layout/Header";
import { SHLOMS_404_FALLBACK_IMG_URL } from "../custom/Shloms404";
import SponsorBanner from "../sponsor-mints/SponsorBanner";

import TokenRevealCarousel from "./TokenRevealCarousel";
import TokenRevealExplore from "./TokenRevealExplore";
import { TokenRevealMedia } from "./TokenRevealMedia";

export type MintProps = {
  editionId: number;
  numTokensMinted: number;
  mintingToken: MintingToken | null;
  tokenIds: string[];
  txHash: string;
  referrer?: string;
  sponsorMintOrderId?: string;
  sponsoredVectorId?: string | null;
};

const ThisAppHeader = () => {
  return (
    <Header
      // Necessary to prevent page from jumping when it appears
      height={0}
      sx={{
        boxSizing: "content-box",
      }}
    >
      <AppHeader />
    </Header>
  );
};

const TokenReveal = () => {
  const { collectionId } = useParams();
  const { isScrollTop } = useIsScrollTop();
  const theme = useTheme();
  const isSmall = useMediaQuery(`(max-width: ${theme.breakpoints.sm - 1}px)`);
  const { width, height } = useViewportSize();
  const { state } = useLocation();
  const { ref: titleAreaRef, height: titleAreaHeight } =
    useElementSize<HTMLDivElement>();
  const { ref: infoAreaRef, height: infoAreaHeight } =
    useElementSize<HTMLDivElement>();
  const [isCarouselReady, setIsCarouselReady] = useState(false);
  const assetElement = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [mediaHeight, setMediaHeight] = useState(0);
  const mintProps = (state as MintProps) || (history.state as MintProps);
  const mintPageHook = useMintPage(collectionId ?? "", {
    referrer: mintProps.referrer,
  });
  const {
    collection,
    mintVectorsLoading,
    loadingClaimMint,
    collectionLoading,
    isCodeGenMint,
    isCollectorChoiceMint,
    isAnySeriesMint,
    edition,
    contentTypeInfo,
  } = mintPageHook;

  const [_, scrollTo] = useWindowScroll();
  const { aspectRatio } = useGenArtAspectRatio(
    collection?.generativeDetails?.captureSettings.viewPort
  );
  const [numerator, denominator] = aspectRatio?.split("/") || [];
  const aspectRatioNum =
    !numerator || !denominator
      ? 1
      : parseInt(numerator) / parseInt(denominator);

  const hPadding = isSmall ? 20 : 80;
  const topPadding = isSmall ? 32 : 40;
  const bottomPadding = isSmall ? 64 : 80;

  function setArtworkSize() {
    const availableWidth = width - hPadding * 2;
    const windowHeight = Math.max(height, 720);
    const availableHeight =
      windowHeight -
      topPadding -
      80 -
      bottomPadding -
      infoAreaHeight -
      titleAreaHeight;

    const targetWidth =
      availableWidth / availableHeight > aspectRatioNum
        ? availableHeight * aspectRatioNum
        : availableWidth;
    const targetHeight =
      availableWidth / availableHeight > aspectRatioNum
        ? availableHeight
        : availableWidth / aspectRatioNum;
    if (!assetElement.current) {
      return;
    }
    assetElement.current.style.width = `${targetWidth}px`;
    assetElement.current.style.height = `${targetHeight}px`;
    titleAreaRef.current.style.top = `${
      assetElement.current.offsetTop - titleAreaHeight
    }px`;
    if (infoAreaRef?.current) {
      infoAreaRef.current.style.top = `${
        assetElement.current.offsetTop + targetHeight
      }px`;
      infoAreaRef.current.style.width = `${targetWidth}px`;
    }
    setMediaHeight(targetHeight);
  }

  useEffect(() => {
    setArtworkSize();
  }, [width, height, isCarouselReady, titleAreaHeight, infoAreaHeight]);

  useEffect(() => {
    scrollTo({ x: 0, y: 0 });
  }, []);

  if (!collectionId) {
    return <NotFound />;
  }

  if (collectionLoading || mintVectorsLoading || loadingClaimMint) {
    return <LoadingPage />;
  }

  if (!collection) {
    return <NotFound />;
  }

  if (!mintProps) {
    navigate(getMintPageUrl(collection));
    return null;
  }

  let imageUrl = edition?.image;
  let animationUrl = edition?.animation;
  console.log(animationUrl);
  console.log(mintProps.mintingToken?.animationUrl);

  if (isCollectorChoiceMint) {
    imageUrl = mintProps.mintingToken?.imageUrl || "";
    animationUrl = mintProps.mintingToken?.animationUrl || "";
  }

  const isHtml = contentTypeInfo?.isHtml ?? false;

  return (
    <MintContext.Provider value={mintPageHook}>
      <AppSEO title={`${collection.name} - Reveal`} />
      <AppShell header={!isScrollTop ? <ThisAppHeader /> : <></>} padding={0}>
        <SponsorBanner
          sponsorMintOrderId={mintProps.sponsorMintOrderId}
          sponsoredVectorId={mintProps.sponsoredVectorId}
        />
        <Container size="xl" mb={80}>
          <EmailUpdates
            imageUrl={collection.collectionImage ?? ""}
            autoOpen
            description="Don't miss a beat—sign up for email notifications about offers for your items and receive real-time offer alerts."
          />
          <Stack
            sx={{
              gap: 40,
            }}
          >
            <Stack
              sx={{
                gap: 32,
                [`@media (min-width: ${theme.breakpoints.md}px)`]: {
                  gap: 40,
                },
                paddingTop: 40,
                paddingBottom: 40,
                display: "flex",
                justifyContent: "center",
                minHeight: "100vh",
                position: "relative",
              }}
              align="center"
            >
              <Flex
                gap={4}
                direction="column"
                align="center"
                ref={titleAreaRef}
              >
                <Text
                  size="sm"
                  align="center"
                  color={theme.colors.secondaryText[0]}
                >
                  Congratulations, you minted {mintProps.numTokensMinted}{" "}
                  {isAnySeriesMint && "unique "}
                  iteration{mintProps.numTokensMinted > 1 ? "s" : ""} of
                </Text>
                <Title order={5}>{collection.name}</Title>
              </Flex>
              {collection?.nftOwnershipMarketplaceFeeCampaigns?.length &&
              !collection?.nftOwnershipMarketplaceFeeCampaigns[0].nftOwnership
                ?.tokenId ? (
                <Card
                  style={{ border: "1px solid #17B26A" }}
                  bg={"#DCFAE6"}
                  p={16}
                >
                  {" "}
                  {/* TODO: change hardcoded colors to theme colors */}
                  <Flex justify="space-between" align="center">
                    <CoinsStacked02 color="#17B26A" />
                    <Text color="#17B26A" ml={12} size="sm">
                      {collection?.nftOwnershipMarketplaceFeeCampaigns[0]
                        .nftOwnership?.revealMessage ??
                        `Minting earned you ${
                          collection?.nftOwnershipMarketplaceFeeCampaigns[0]
                            .feeBps / 100
                        }% marketplace fees`}
                    </Text>
                  </Flex>
                </Card>
              ) : (
                <></>
              )}
              <Box>
                {isCodeGenMint || collection.reveal || isHtml ? (
                  <TokenRevealCarousel
                    collection={collection}
                    disableIFrame={
                      mintProps.tokenIds.length >
                      parseInt(
                        collection.flagVariations?.safeIframeLimit ?? "0"
                      )
                    }
                    mintedTokenIds={mintProps.tokenIds}
                    infoAreaRef={infoAreaRef}
                    carouselRef={assetElement}
                    setIsCarouselReady={setIsCarouselReady}
                    isGenerative={isCodeGenMint || isHtml}
                    height={mediaHeight}
                    overrideImageUrl={
                      collection.flagVariations.shloms404UI
                        ? SHLOMS_404_FALLBACK_IMG_URL
                        : undefined
                    }
                  />
                ) : (
                  <Stack align="center" justify="center" w="100%" p={0}>
                    <Center w="100%" px={{ base: 0, md: 80 }}>
                      <TokenRevealMedia
                        imageUrl={imageUrl}
                        animationUrl={animationUrl}
                        itemRef={assetElement}
                        customHeight={mediaHeight}
                      />
                    </Center>
                    <ReferralButton color={OUTLINE_COLOR} />
                  </Stack>
                )}
              </Box>
              <Box sx={{ position: "absolute", bottom: 20 }}>
                <LargeChevronDown />
              </Box>
            </Stack>
            <TokenRevealExplore
              isAnySeriesMint={isAnySeriesMint}
              collection={collection}
              txHash={mintProps.txHash}
            />
          </Stack>
        </Container>
      </AppShell>
    </MintContext.Provider>
  );
};

export default TokenReveal;
