import { Suspense } from "react";

import { AuthProvider } from "@hl/shared-features/lib/features/auth";
import { AppBootstrap } from "@hl/shared-features/lib/features/auth/AppBootstrap";
import TransactionProvider from "@hl/shared-features/lib/features/evm-tx/TransactionProvider";
import { NotFound } from "@hl/shared-features/lib/features/layout/NotFound";
import MarketplaceProvider from "@hl/shared-features/lib/features/marketplace/provider";
import { ModalStack } from "@hl/shared-features/lib/features/modal";
import { WatchlistAndFollowContextProvider } from "@hl/shared-features/lib/features/watchlist-and-follow/WatchlistAndFollowContext";
import loadable from "@loadable/component";
import { Route, Routes } from "react-router-dom";

import { MINT_URL, TOOLS_URL } from "./config";
import MintPageByCollectionId from "./features/MintPage/MintPageByCollectionId";
import MintPageByOnChainId from "./features/MintPage/MintPageByOnChainId";
import TokenMetadata from "./features/MintPage/TokenMetadata";
import TokenReveal from "./features/MintPage/token-reveal/TokenReveal";
import { HomePage } from "./features/home";
import Root from "./features/layout/Root";

const TokenDetailsSwitch = loadable(
  () => import("~features/token-details/TokenDetailsSwitch")
);

export const App = () => {
  return (
    <Suspense fallback={""}>
      <AuthProvider>
        <AppBootstrap>
          <TransactionProvider>
            <MarketplaceProvider>
              <WatchlistAndFollowContextProvider>
                <ModalStack>
                  <Root>
                    <Routes>
                      <Route path="/" element={<HomePage />} />
                      <Route
                        path={MINT_URL.tokens.mint}
                        element={<MintPageByCollectionId />}
                      />
                      <Route
                        path={MINT_URL.tokens.mintByOnChainId}
                        element={<MintPageByOnChainId />}
                      />
                      <Route
                        path={MINT_URL.tokens.metadata}
                        element={<TokenMetadata />}
                      />
                      <Route
                        path={MINT_URL.tokens.detail}
                        element={<TokenDetailsSwitch />}
                      />
                      <Route
                        path={MINT_URL.tokens.detailByRevealId}
                        element={<TokenDetailsSwitch useTokenByRevealIdQuery />}
                      />
                      <Route
                        path={MINT_URL.tokens.reveal}
                        element={<TokenReveal />}
                      />
                      <Route
                        path={"*"}
                        element={<NotFound redirectUrl={TOOLS_URL.base} />}
                      />
                    </Routes>
                  </Root>
                </ModalStack>
              </WatchlistAndFollowContextProvider>
            </MarketplaceProvider>
          </TransactionProvider>
        </AppBootstrap>
      </AuthProvider>
    </Suspense>
  );
};
